import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "footer"
    }}>{`Footer`}</h1>
    <h2 {...{
      "id": "newsletter"
    }}>{`Newsletter`}</h2>
    <p><img alt="Newsletter" src={require("../img/home1-newsletter.png")} /></p>
    <p>{`You can configure the newsletter block, show/hide or change colors in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Footer`}</strong>{` > `}<strong parentName="p">{`Newsletter`}</strong>{`:`}</p>
    <p><img alt="Configure newsletter" src={require("../img/theme-editor-newsletter.png")} /></p>
    <h2 {...{
      "id": "links"
    }}>{`Links`}</h2>
    <p><img alt="Footer" src={require("../img/home1-footer.png")} /></p>
    <p>{`You can change colors in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Footer`}</strong>{`:`}</p>
    <p><img alt="Theme editor main footer" src={require("../img/theme-editor-main-footer.png")} /></p>
    <p>{`You can also show/hide About Us block, Pages, Categories, Brands, or configure number of links to show.`}</p>
    <h2 {...{
      "id": "payment-icons"
    }}>{`Payment Icons`}</h2>
    <p><img alt="Theme editor payment icons" src={require("../img/theme-editor-payment-icons.png")} /></p>
    <p>{`To show/hide the payment icons, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Payment Icons`}</strong>{` seciton, tick on any icons you want to show.`}</p>
    <h2 {...{
      "id": "credit-links"
    }}>{`Credit Links`}</h2>
    <p><img alt="Theme editor credit links" src={require("../img/theme-editor-credits.png")} /></p>
    <p>{`To show/hide the credit links, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Footer`}</strong>{` section, tick on the checkboxes as showing above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      